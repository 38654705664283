import React from 'react';

import FlexBox from '@components/layout/FlexBox';
import settings from '@settings';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

const CalendarElement = ({event}) => {
	const dateHandler = dates => {
		if (dates.length === 0) {
			return null;
		}
		if (dates.length === 1) {
			return settings.functions.transformDate(dates[0]) ;
		}
		if (dates.length > 1) {
			return `${settings.functions.transformDate(dates[0])} - ${settings.functions.transformDate(dates[dates.length - 1])}`;
		}
		return null;
	};

	return (
		<FlexBox
			direction="row"
			align="center"
			justify="space-between"
			hasFullWidth
			changeToColumn
			gap={settings.sizes.small}
			styles={{padding: settings.padding.medium, 'p': {marginBottom: 0}}}
		>
			<p css={{ fontWeight:'600 !important', width: '300px'}}>
				{event.title}
			</p> 
			<p css={{ fontSize: '.9rem !important', width: '300px'}}>
				{dateHandler(event.date)}
			</p>
			<p css={{ fontSize: '.9rem !important', width: '300px'}}>
				{event.eventLocation?.name}
			</p>
			<FlexBox
				direction='row'
				align='center'
				gap={settings.sizes.small}
				styles={{width: '80px'}}
			>
				{event?.eventLocation?.nation &&
					<>
						<GatsbyImage image={getImage(event?.eventLocation?.nation?.flag)} alt={event?.eventLocation?.nation?.name} height={18}  />
						<p css={{ fontSize: '.9rem !important'}}>
							{event.eventLocation.nation.short}
						</p>
					</>
				}
			</FlexBox>
		</FlexBox>
	);
};

CalendarElement.propTypes = {
	event: PropTypes.object.isRequired
};

export default CalendarElement;