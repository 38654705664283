import React from 'react';

import Container from '@components/Container';
import FlexBox from '@components/layout/FlexBox';
import Wrapper from '@components/layout/Wrapper';
import Divider from '@components/surfaces/Divider';
import Expandable from '@components/surfaces/Expandable';
import useGetSeasons from '@hooks/useGetSeasons';
import Layout from '@mainComponents/Layout';
import settings from '@settings';
import PropTypes from 'prop-types';

import CalendarElement from './components/CalendarElement';

const Calendar = ({ pageContext: {uri} }) => {
	const  {seasons} = useGetSeasons();

	const content = events => 
		<>
			{events.map(event => (
				<React.Fragment key={event.remoteId}>
					<CalendarElement  event={event} />
					<Divider gap='small' />
				</React.Fragment>
			))}
		</>;


	return (
		<Layout
			headerType='page-without-image'
			showHeader
			path={uri}
			pageTitle='Calendar'
			pageTopline='Competitions an Championships'
			pageDescription='Overview over past and upcoming events'
		>
			<Wrapper>
				<Container>
					{seasons.map(season => (
						<FlexBox
							key={season.remoteId}
							direction='column'
							align='stretch'
							gap={settings.sizes.medium}
							styles={{marginBottom: settings.sizes.extraLarge}}
						>
							{season.calendars.sort((a,b) => (a.order - b.order)).map((calendarElement, index) => 
								<Expandable 
									key={calendarElement.remoteId}
									title={calendarElement.title}
									mainContent={content(calendarElement.events)}
									isExpanded={index === 0}
								/>
							)}

						</FlexBox>
					))}
				</Container>
			</Wrapper>
		</Layout>
	);
};

Calendar.propTypes = {
	pageContext: PropTypes.object.isRequired
};

export default Calendar;