/* eslint-disable react/prop-types */
import React from 'react';

import Calendar from '@content/Calendar';
import SiteHead from '@mainComponents/SiteHead';

const calendar = pageContext => <Calendar pageContext={pageContext} />;

export const Head = ({location}) => <SiteHead location={location} />;

export default calendar;